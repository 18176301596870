<template>
  <div>
    <fieldsRows
      :templateContent="templateContent"
      @field-value-changed="updateFieldValue(...arguments)"
      :valueLine="localStoredValue"
      @type-changed="changeTargetType"
    ></fieldsRows>
    <div class="mb-3">
      <v-card class="mb-6 mt-3" elevation="0" outlined>
        <containerTitle title="Evaluation"> </containerTitle>
        <v-card-text>
          <lineFieldsArray
            :fieldAttrRow="{}"
            :fields="fields"
            :templateContent="result"
            :value="evalArray"
            @line-field-input="checkInput(...arguments)"
            :headerTitle="true"
            :addable="true"
            addButtTitle="Add Eval"
            :linePrefix="stepType + '_' + stepID + '_eval'"
            :isValid="isValid"
            v-on="$listeners"
          ></lineFieldsArray>
        </v-card-text>
      </v-card>
    </div>

    <explorerOnError
      v-model="onErrorVal"
      :result="{
        stepType: this.stepType,
        stepID: this.stepID,
        disableAllInputs: this.result.disableAllInputs,
        enableAdd: false,
        contentID: this.stepType + '_' + this.stepID,
      }"
    ></explorerOnError>
    <explorerCollectStatistics v-model="statistic" :result="result">
    </explorerCollectStatistics>
  </div>
</template>
<script>
import fieldsRows from "@/commonComponents/fieldsRows.vue";
import teststepDefault from "@/cfg/teststep-default.json";
import lineFieldsArray from "@/commonComponents/lineFieldsArray.vue";
import options from "@/cfg/options.json";
import lineButtons from "@/cfg/lineButtons.json";
import containerTitle from "@/commonComponents/containerTitle.vue";
import explorerOnError from "@/components/dynamic/explorerOnError.vue";
import explorerCollectStatistics from "@/components/legacy/explorerCollectStatistics.vue";

export default {
  components: {
    fieldsRows,
    lineFieldsArray,
    containerTitle,
    explorerOnError,
    explorerCollectStatistics,
  },
  data() {
    return {
      options: options,
    };
  },
  props: ["result", "stepStoredValue", "stepType", "stepID", "isValid"],
  computed: {
    statistic: {
      get() {
        return this.stepStoredValue.statistic;
      },
      set(newVal) {
        this.$set(this.stepStoredValue, "statistic", newVal);
        this.$emit("input", this.stepStoredValue);
      },
    },
    evalArray: {
      get() {
        let pairArray = [];
        if (
          typeof this.stepStoredValue["res"] != "undefined" &&
          typeof this.stepStoredValue["res"]["eval"] != "undefined" &&
          typeof this.stepStoredValue["res"]["eval"] == "object" &&
          Array.isArray(this.stepStoredValue["res"]["eval"])
        ) {
          pairArray = this.stepStoredValue["res"]["eval"];
        }
        return pairArray;
      },
    },
    onErrorVal: {
      get() {
        return this.stepStoredValue.res;
      },
      set(newVal) {
        this.$emit("input", newVal);
      },
    },
    checkUplink() {
      let value = false;
      if (
        typeof this.localStoredValue != "undefined" &&
        typeof this.localStoredValue.uplink != "undefined" &&
        this.localStoredValue.uplink == 1
      ) {
        value = true;
      }
      return value;
    },
    checkDownlink() {
      let value = false;
      if (
        typeof this.localStoredValue != "undefined" &&
        typeof this.localStoredValue.downlink != "undefined" &&
        this.localStoredValue.downlink == 1
      ) {
        value = true;
      }
      return value;
    },
    fields() {
      let fields = {
        type: {
          type: "combobox",
          associatedOption: [
            ["uplink", "Uplink in bps"],
            ["downlink", "Downlink in bps"],
            ["jitter", "Jitter in ms"],
            ["ping", " Ping in ms"],
          ],
          name: "Type",
          fieldAttrInput: { class: "required" },
        },
        operator: {
          type: "select",
          associatedOption: this.result.additional.explorerEvalOperator,
          name: "Operator",
          fieldAttrInput: { class: "required" },
        },
        content: {
          type: "number",
          name: "Value",
          fieldAttrInput: { class: "required" },
        },
        else: {
          input: false,
          type: "string",
          string: "else",
          colAttrs: {
            style: "max-width: 50px;",
          },
        },
        result: {
          type: "select",
          associatedOption: options.ldapResult,
          name: "Result",
          fieldAttrInput: { class: "required" },
          default: "error",
        },
        action: {
          type: "select",
          associatedOption: options.evalAction,
          name: "Action",
          default: "stopTest",
          fieldAttrInput: { class: "required" },
        },
        info: {
          type: "text",
          name: "Info",
          fieldAttrInput: { class: "" ,maxlength:'100' },
        },
        remove: lineButtons.remove,
      };
      return fields;
    },

    localStoredValue: {
      get() {
        let stepStoredValue = this.stepStoredValue;
        if (
          typeof this.stepStoredValue.startWithinMinutes == "undefined" ||
          typeof this.stepStoredValue.res == "undefined"
        ) {
          stepStoredValue = JSON.parse(
            JSON.stringify({
              ...teststepDefault[this.stepType],
              duration: "10",
              uplink: 0,
              downlink: 0,
              res: {
                onError: {
                  action: "stopTest",
                  result: "error",
                },
                eval: [],
              },
              statistic: {
                collectTime: "monitoring_time",
              },
            })
          );

          this.$emit("stored-value-changed", stepStoredValue);
        }

        if (typeof stepStoredValue.res.eval == "undefined") {
          this.$set(stepStoredValue.res, "eval", []);
          this.$emit("stored-value-changed", stepStoredValue);
        }
        if (typeof stepStoredValue.target == "undefined") {
          this.$set(stepStoredValue, "target", {});
          this.$emit("stored-value-changed", stepStoredValue);
        }
        if (
          typeof stepStoredValue.target.host == "undefined" &&
          typeof stepStoredValue.host != "undefined"
        ) {
          this.$set(stepStoredValue.target, "host", stepStoredValue.host);
          //if deleted then not work in old
          //this.$delete(stepStoredValue, "host");
          this.$emit("stored-value-changed", stepStoredValue);
        }

        /*   if (
            typeof stepStoredValue.target.port == "undefined" &&
            typeof stepStoredValue.port != "undefined"
          ) {
            this.$set(
              stepStoredValue.target,
              "port",
              stepStoredValue.port
            );
            //this.$delete(stepStoredValue, "host");
            this.$emit("stored-value-changed", stepStoredValue);
          }*/
        /*  if (typeof stepStoredValue.omit == "undefined") {
            this.$set(
              stepStoredValue,
              "omit",
              teststepDefault[this.stepType].omit
            );

            this.$emit("stored-value-changed", stepStoredValue);
          }*/
        if (typeof stepStoredValue.parallel == "undefined") {
          this.$set(
            stepStoredValue,
            "parallel",
            teststepDefault[this.stepType].parallel
          );

          this.$emit("stored-value-changed", stepStoredValue);
        }

        return stepStoredValue;
      },
      set(newVal) {
        this.$emit("stored-value-changed", newVal);
      },
    },
    templateContent() {
      let templateContent = {
        fields: {
          timeLine: {
            type: "line",
            fields: {
              startWithinMinutes: {
                type: "number",
                name: "Start Within Minutes",
                fieldAttrInput: {
                  class: " ",
                },
                colAttrs: { style: "max-width:170px", class: "" },
              },
              finishedWithinMinutes: {
                type: "number",
                name: "Finished Within Minutes",
                fieldAttrInput: {
                  class: " ",
                },
                colAttrs: { style: "max-width:170px", class: "" },
              },
              maxDuration: {
                type: "number",
                name: "Maximum Duration (s)",
                fieldAttrInput: {
                  class: "",
                },
                colAttrs: { style: "max-width:170px", class: "" },
              },
            },
          },
          lstBlock: {
            inputFieldFormatter: "explorerLstBlock",
          },
        },
        stepType: this.stepType,
        stepID: this.stepID,
        disableAllInputs: this.result.disableAllInputs,
        enableAdd: false,
        //contentID: this.stepType + "_" + this.stepID,
        contentID: this.stepType,
      };
      return templateContent;
    },
  },
  methods: {
    changeTargetType(value) {
      this.$set(this.stepStoredValue.target, "type", value);
      this.$emit("stored-value-changed", this.stepStoredValue);
    },
    checkInput(newValueArray) {
      let localStoredStep = { ...this.stepStoredValue };
      if (typeof localStoredStep.res == "undefined") {
        this.$set(localStoredStep, "res", {});
      }
      if (typeof localStoredStep.res.eval == "undefined") {
        this.$set(localStoredStep.res, "eval", []);
      }
      this.$set(localStoredStep.res, "eval", newValueArray);
      this.$emit("stored-value-changed", localStoredStep);
    },

    updateFieldValue(field, value) {
      //  console.log("CORE", field, value);
      let localStoredStep = { ...this.stepStoredValue };
      if (field != "lstBlock" && field != "testDirection") {
        this.$set(localStoredStep, field, value);
      }
      this.$emit("stored-value-changed", localStoredStep);
    },
  },
};
</script>


